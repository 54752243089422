import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import parse from 'html-react-parser';

import '../styles/templates/ServicesPage.scss'

// Components
import { TitleDefault, ContentDefault } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Team from 'components/Team'

import '../styles/templates/AboutContact.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    contact: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        contact_header_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        contact_address
        contact_email
        contact_phone
        maps_url
        about_text
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

function AboutContactPage({
  data: {
    contact: {
      path,
      title,
      acf: {
        contact_header_image,
        contact_address,
        contact_email,
        contact_phone,
        maps_url,
        about_text,
      },
      yoast_meta,
    },
  },
  pageContext: {
    breadcrumb: { crumbs },
  },
}) {
  return (
    <Layout>
      {contact_header_image && contact_header_image.localFile ? (
        <SEO
          yoast={{ meta: yoast_meta }}
          pathname={path}
          image={contact_header_image.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: yoast_meta }} pathname={path} />
      )}
      <div className="about-template">
        <section className="heroimage position-relative d-flex">
          <div className="container context">
            <div className="row py-4">
              <div className="col-lg-6">
                <TitleDefault className="mb-3 color-text-light">
                  <span>{parse(title)}</span>
                </TitleDefault>
                <div className="line mb-4" />
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=">"
                  crumbLabel={parse(title)}
                />
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
          <div className="heroimage-image row position-absolute">
            <div className="col-12 col-lg-5 fill" />
            <div className="heroimage-image-container col-lg-7 p-0">
              <BackgroundImage
                className="bgimage"
                fluid={contact_header_image.localFile.childImageSharp.fluid}
              />
            </div>
          </div>
        </section>
        <section className="contact-row py-4">
          <div className="container">
            <div className="row py-lg-5 py-2">
              <div className="col-lg-1 d-none d-lg-block" />
              <div className="col-lg-4">
                <ContentDefault>
                  <h2 className="mb-4">Contact</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: contact_address }}
                    className="mb-lg-5 mb-sm-3"
                  />
                  <div className="mb-3">
                    <h3>Phone</h3>
                    <a href={`tel:${contact_phone}`}>{contact_phone}</a>
                  </div>
                  <div>
                    <h3>Email</h3>
                    <a href={`mailto:${contact_email}`}>{contact_email}</a>
                  </div>
                </ContentDefault>
              </div>
              <div className="col-lg-7 pt-4 pt-lg-0">
                <iframe
                  title="adres"
                  src={maps_url}
                  width="591"
                  height="330"
                  allowFullScreen=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="about-row">
          <div className="container">
            <div className="row pt-0 pt-lg-4 pb-5">
              <div className="col-lg-1 d-none d-lg-block" />
              <div className="col-lg-6">
                <ContentDefault>
                  {parse(about_text)}
                </ContentDefault>
              </div>
            </div>
          </div>
        </section>
        <section className="team-row">
          <div className="container">
            <div className="row pb-5">
              <div className="col-lg-1 d-none d-lg-block" />
              <Team />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AboutContactPage
