import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'

import _ from 'lodash'

// Components
import { TitleDefault, ButtonAlt, ContentDefault } from 'components/Elements'

// Images
import mailicon from 'img/mailicon.svg'

// Style
import './Team.scss'

const Team = () => {
  const {
    teamPeoples: { edges: members },
    teamPage, 
  } = useStaticQuery(graphql`
    {
      teamPeoples: allWordpressWpTeam(sort: { fields: date }) {
        edges {
          node {
            acf {
              team_name
              team_function
              team_email
              team_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
      teamPage: wordpressPage(wordpress_id: { eq: 32 }) {
        acf {
          team_title
        }
      }
    }
  `)
  return (
    <div className="team col-lg-11 col-md-12">
      <h2 className="mb-4">{teamPage.acf.team_title}</h2>
      <div className="row">
        {_.map(members, member => (
          <div className="col-lg-4 col-md-6">
            <Img
              className="team-image"
              fluid={member.node.acf.team_image.localFile.childImageSharp.fluid}
            />
            <div className="team-info">
              <div className="team-info-name pl-3 mb-1">
                <p>{member.node.acf.team_name}</p>
              </div>
              <div className="team-info-function pl-3">
                <p>{member.node.acf.team_function}</p>
                <a href={`mailto:${member.node.acf.team_email}`}>
                  <img src={mailicon} width="23" alt="" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Team
